define("itarp-landing-frontend/router", ["exports", "@ember/routing/router", "itarp-landing-frontend/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class Router extends _router.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('choose-account', {
      path: '/'
    });
    this.route('choose-account');
    this.route('landing-page');
    this.route('landing-page-guest', {
      path: 'guest'
    });
    this.route('landing-page-expert', {
      path: 'expert'
    });
    this.route('signin');
    this.route('forgot-password');
    this.route('signup', function () {
      this.route('guest');
      this.route('client');
      this.route('expert');
      this.route('verify');
    });
  });
});