define("itarp-landing-frontend/utils/sso", ["exports", "itarp-landing-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateUri = void 0;
  //SSO Util

  const generateUri = _ref => {
    let {
      isSignUp,
      ssoType,
      userType
    } = _ref;
    const domainHint = ssoType === 'linkedin' ? _environment.default.DOMAIN_HINT_LINKEDIN : _environment.default.DOMAIN_HINT_GOOGLE;
    const redirectUrl = userType === 'expert' ? _environment.default.REDIRECT_URI_EXPERT : _environment.default.REDIRECT_URI_DEFAULT;
    const tenantId = isSignUp ? _environment.default.B2C_SIGNUP_POLICY : _environment.default.B2C_SIGNIN_POLICY;
    const userRole = isSignUp ? `&user_role=${userType}` : '';
    //   return `
    //             https://itarpb2cdevssharma.b2clogin.com/itarpb2cdevssharma.onmicrosoft.com/oauth2/v2.0/authorize?
    //             p=${tenantId}&
    //             client_id=${process.env.CLIENT_ID}&
    //             nonce=defaultNonce&
    //             redirect_uri=${redirectUrl}&
    //             scope=openid&response_type=id_token&prompt=login&user_role=${userType}&
    //             domain_hint=${domainHint}
    //             `;
    return `https://${_environment.default.B2C_TENANT_NAME}.b2clogin.com/${_environment.default.B2C_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/authorize?p=${tenantId}&client_id=${_environment.default.B2C_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(redirectUrl)}&scope=openid&response_type=id_token&prompt=login${userRole}&domain_hint=${domainHint}`;
  };
  _exports.generateUri = generateUri;
});