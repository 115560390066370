define("itarp-landing-frontend/components/autocomplete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="autocomplete">
    <Input
      @type="text"
      @value={{@value}}
      {{on "input" this.onInput}}
      {{on "keydown" this.onKeydown}}
      ...attributes
    />
    {{#if @options.length}}
      <div
        class="ember-basic-dropdown-content ember-power-select-dropdown ember-basic-dropdown-content--below"
        {{on "focusout" this.closeAllLists}}
      >
        <ul class="ember-power-select-options" role="listbox">
          {{#each @options as |item|}}
            <li
              class="ember-power-select-option"
              aria-current="false"
              {{on "click" (fn this.onClick item)}}
              {{on "mouseover" (fn this.onMouseover item)}}
            >
              {{item}}
            </li>
          {{/each}}
        </ul>
      </div>
    {{/if}}
  
  </div>
  */
  {
    "id": "cvZsKTWl",
    "block": "[[[10,0],[14,0,\"autocomplete\"],[12],[1,\"\\n  \"],[8,[39,0],[[17,1],[4,[38,1],[\"input\",[30,0,[\"onInput\"]]],null],[4,[38,1],[\"keydown\",[30,0,[\"onKeydown\"]]],null]],[[\"@type\",\"@value\"],[\"text\",[30,2]]],null],[1,\"\\n\"],[41,[30,3,[\"length\"]],[[[1,\"    \"],[11,0],[24,0,\"ember-basic-dropdown-content ember-power-select-dropdown ember-basic-dropdown-content--below\"],[4,[38,1],[\"focusout\",[30,0,[\"closeAllLists\"]]],null],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"ember-power-select-options\"],[14,\"role\",\"listbox\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"          \"],[11,\"li\"],[24,0,\"ember-power-select-option\"],[24,\"aria-current\",\"false\"],[4,[38,1],[\"click\",[28,[37,5],[[30,0,[\"onClick\"]],[30,4]],null]],null],[4,[38,1],[\"mouseover\",[28,[37,5],[[30,0,[\"onMouseover\"]],[30,4]],null]],null],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\",\"@options\",\"item\"],false,[\"input\",\"on\",\"if\",\"each\",\"-track-array\",\"fn\"]]",
    "moduleName": "itarp-landing-frontend/components/autocomplete.hbs",
    "isStrictMode": false
  });
  let AutocompleteComponent = (_class = class AutocompleteComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentFocus", _descriptor, this);
    }
    onInput(event) {
      let val = event.target.value;
      if (!val) {
        this.args.clearOptions();
        return;
      }
      this.args.onInput(val);
      this.currentFocus = -1;
    }
    onKeydown(event) {
      const arrowDown = 40;
      const arrowUp = 38;
      const enter = 13;
      let items = document.getElementsByClassName('ember-power-select-option');
      if (event.keyCode === arrowDown) {
        this.currentFocus++;
        this.addActive(items);
      } else if (event.keyCode === arrowUp) {
        this.currentFocus--;
        this.addActive(items);
      } else if (event.keyCode === enter) {
        event.preventDefault();
        if (this.currentFocus > -1 && items[this.currentFocus]) {
          items[this.currentFocus].click();
        } else {
          this.args.clearOptions();
        }
      }
    }
    onClick(item) {
      this.args.onSelect(item);
      this.args.clearOptions();
    }
    addActive(items) {
      if (!items) return false;
      this.removeActive(items);
      if (this.currentFocus >= items.length) this.currentFocus = 0;
      if (this.currentFocus < 0) this.currentFocus = items.length - 1;
      items[this.currentFocus].setAttribute('aria-current', 'true');
      items[this.currentFocus].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
    removeActive(items) {
      for (let item of items) {
        item.setAttribute('aria-current', 'false');
      }
    }
    onMouseover(item) {
      this.currentFocus = this.args.options.indexOf(item);
      let items = document.getElementsByClassName('ember-power-select-option');
      this.addActive(items);
    }
    closeAllLists(event) {
      if (!event || !event.target.classList.contains('ember-power-select-option')) {
        this.args.clearOptions();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentFocus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addActive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeActive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseover", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeAllLists", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeAllLists"), _class.prototype)), _class);
  _exports.default = AutocompleteComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AutocompleteComponent);
});