define("itarp-landing-frontend/components/cards/expert-portal", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="component-wrapper">
    <div class="card-holder">
      <div class="lp-card lp-card--frost lp-card--centered">
        <div class="lp-card__body">
          <span class="lp-card__icon for-expert"></span>
          <h3 class="lp-card__title">
            Expert Portal
          </h3>
          <p class="lp-card__text lp-card__text--lg">
            If you are an expert interested in new job opportunities and projects,
            create a profile on our Talent-on-Demand marketplace. If you become a
            member of TalentClouds, hiring managers at leading enterprises will
            send you direct job invitations for jobs matching your skillset.
          </p>
        </div>
        <div class="lp-card__footer has-hp">
          {{yield}}
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "8DTpWueo",
    "block": "[[[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-holder\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"lp-card lp-card--frost lp-card--centered\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"lp-card__body\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"lp-card__icon for-expert\"],[12],[13],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"lp-card__title\"],[12],[1,\"\\n          Expert Portal\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"lp-card__text lp-card__text--lg\"],[12],[1,\"\\n          If you are an expert interested in new job opportunities and projects,\\n          create a profile on our Talent-on-Demand marketplace. If you become a\\n          member of TalentClouds, hiring managers at leading enterprises will\\n          send you direct job invitations for jobs matching your skillset.\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"lp-card__footer has-hp\"],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-landing-frontend/components/cards/expert-portal.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});