define("itarp-landing-frontend/routes/landing-page", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let LandingPageRoute = (_class = class LandingPageRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _initializerDefineProperty(this, "links", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
    }
    async model() {
      let response = await fetch('/api/users/me', {
        headers: {
          Authorization: `Bearer ${this.auth.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    }
    getExpertUrl(incompleteProfile) {
      return this.links.withToken(`/expert/${incompleteProfile ? 'autofill' : 'opportunities'}`);
    }
    getClientUrl(hasOpportunities) {
      const {
        open,
        pendingApproval,
        draft
      } = hasOpportunities;
      if (open) {
        return this.links.withToken('/opportunities/experfy');
      } else if (pendingApproval) {
        return this.links.withToken('/opportunities?status=pending_approval');
      } else if (draft) {
        return this.links.withToken('/opportunities?status=draft');
      }
      return this.links.withToken('/opportunities/experfy');
    }
    getAdminURL() {
      return this.links.withToken('/tenant/dashboard');
    }
    getStaffURL() {
      return this.links.withToken('/task-dashboard');
    }
    getRedirectUrl(user) {
      const {
        profile: {
          user_type: userType
        },
        profile_completion_status,
        hasOpportunities
      } = user;
      if (userType === 'expert') {
        return this.getExpertUrl(profile_completion_status !== 'complete');
      } else if (userType === 'tod_client') {
        return this.getClientUrl(hasOpportunities);
      } else if (userType === 'site_admin') {
        return this.getAdminURL();
      } else if (userType === 'experfy_staff_user' || userType === 'experfy_staff_admin_user') {
        return this.getStaffURL();
      }
      return '';
    }
    afterModel(model, transition) {
      const url = this.getRedirectUrl(model);
      if (url) {
        return window.location.assign(url);
      } else {
        this.router.transitionTo('landing-page-guest');
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "links", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = LandingPageRoute;
});