define("itarp-landing-frontend/routes/landing-page-expert", ["exports", "@ember/routing/route", "@ember/service", "fetch"], function (_exports, _route, _service, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let LandingPageExpertRoute = (_class = class LandingPageExpertRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _initializerDefineProperty(this, "links", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
    }
    beforeModel(transition) {
      this.auth.transition(transition).requireAuthentication().requireRole('expert');
    }
    async model() {
      let response = await (0, _fetch.default)('/api/users/me', {
        headers: {
          Authorization: `Bearer ${this.auth.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    }
    afterModel(model, transition) {
      const incompleteProfile = model.profile.profile_completion_status !== 'complete';
      const url = this.links.withToken(`/expert/${incompleteProfile ? 'autofill' : 'opportunities'}`);
      return window.location.assign(url);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "links", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = LandingPageExpertRoute;
});