define("itarp-landing-frontend/components/cards/podcast", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="component-wrapper">
    <div class="card-holder">
      <div class="lp-podcast">
        <h4 class="lp-podcast__title">
          <span class="lp-podcast__title__thin">Future</span>
          <span class="lp-podcast__title__thin">Of Work</span>
          <span class="lp-podcast__title__bold">Pioneers</span>
          <span class="lp-podcast__title__dark">Podcast</span>
        </h4>
        <p class="lp-podcast__text">
          <span class="lp-podcast__text__label">Latest:</span>
          Jeff Wald, Co-founder, WorkMarket
        </p>
        <a
          class="lp-podcast__link"
          href="https://www.futureworkpodcast.com"
          target="_blank"
        ></a>
      </div>
    </div>
  </div>
  */
  {
    "id": "+Qwxsjsk",
    "block": "[[[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-holder\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"lp-podcast\"],[12],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"lp-podcast__title\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"lp-podcast__title__thin\"],[12],[1,\"Future\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"lp-podcast__title__thin\"],[12],[1,\"Of Work\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"lp-podcast__title__bold\"],[12],[1,\"Pioneers\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"lp-podcast__title__dark\"],[12],[1,\"Podcast\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"lp-podcast__text\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"lp-podcast__text__label\"],[12],[1,\"Latest:\"],[13],[1,\"\\n        Jeff Wald, Co-founder, WorkMarket\\n      \"],[13],[1,\"\\n      \"],[10,3],[14,0,\"lp-podcast__link\"],[14,6,\"https://www.futureworkpodcast.com\"],[14,\"target\",\"_blank\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-landing-frontend/components/cards/podcast.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});