define("itarp-landing-frontend/controllers/choose-account", ["exports", "@ember/controller", "itarp-landing-frontend/config/environment", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _environment, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ChooseAccountController = (_class = class ChooseAccountController extends _controller.default {
    buildB2CUrl(B2C_POLICY, redirectUrl) {
      const {
        B2C_TENANT_NAME,
        B2C_CLIENT_ID,
        B2C_REDIRECT_URI
      } = _environment.default;
      return ['https://', B2C_TENANT_NAME, '.b2clogin.com/', B2C_TENANT_NAME, '.onmicrosoft.com/oauth2/v2.0/authorize?p=', B2C_POLICY, '&client_id=', B2C_CLIENT_ID, '&nonce=defaultNonce&redirect_uri=', redirectUrl ? redirectUrl : B2C_REDIRECT_URI, '&scope=openid&response_type=id_token&prompt=login'].join('');
    }
    get signInUrl() {
      const {
        B2C_SIGNIN_POLICY
      } = _environment.default;
      return this.buildB2CUrl(B2C_SIGNIN_POLICY);
    }
    get clientSignUpUrl() {
      const {
        B2C_SIGNUP_POLICY,
        CLIENT_ROLE
      } = _environment.default;
      return this.buildB2CUrl(B2C_SIGNUP_POLICY).concat(`&user_role=${CLIENT_ROLE}`);
    }
    get talentSignUpUrl() {
      const {
        B2C_SIGNUP_POLICY,
        EXPERT_REDIRECT_URL,
        EXPERT_ROLE
      } = _environment.default;
      return this.buildB2CUrl(B2C_SIGNUP_POLICY, EXPERT_REDIRECT_URL).concat(`&user_role=${EXPERT_ROLE}`);
    }
    get traineeSignUpUrl() {
      const {
        B2C_SIGNUP_POLICY,
        GUEST_ROLE
      } = _environment.default;
      return this.buildB2CUrl(B2C_SIGNUP_POLICY).concat(`&user_role=${GUEST_ROLE}`);
    }
    get isUserDoesntExistError() {
      if (!this.errors) return false;
      return this.errors.any(error => error.includes('User does not exist'));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "buildB2CUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "buildB2CUrl"), _class.prototype)), _class);
  _exports.default = ChooseAccountController;
});