define("itarp-landing-frontend/routes/signup/verify", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SignupVerifyRoute extends _route.default {
    setupController(controller) {
      super.setupController(...arguments);
      controller.initCounter();
    }
  }
  _exports.default = SignupVerifyRoute;
});