define("itarp-landing-frontend/components/power-datepicker", ["exports", "ember-power-datepicker/components/power-datepicker"], function (_exports, _powerDatepicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _powerDatepicker.default;
    }
  });
});