define("itarp-landing-frontend/controllers/signup/expert", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "itarp-landing-frontend/utils/sso", "itarp-landing-frontend/config/environment", "@ember/service", "itarp-landing-frontend/utils/validation", "ember-fetch/errors"], function (_exports, _controller, _tracking, _object, _sso, _environment, _service, _validation, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SignupExpertController = (_class = class SignupExpertController extends _controller.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['opportunityId', 'email', 'token', 'id_token', 'talentCloudId']);
      _initializerDefineProperty(this, "links", _descriptor, this);
      _initializerDefineProperty(this, "auth", _descriptor2, this);
      _initializerDefineProperty(this, "isFirstPage", _descriptor3, this);
      _initializerDefineProperty(this, "isIndividual", _descriptor4, this);
      _initializerDefineProperty(this, "errors", _descriptor5, this);
      _initializerDefineProperty(this, "fieldError", _descriptor6, this);
      _initializerDefineProperty(this, "isSignUpInProgress", _descriptor7, this);
      _initializerDefineProperty(this, "id_token", _descriptor8, this);
      _initializerDefineProperty(this, "opportunityId", _descriptor9, this);
      _initializerDefineProperty(this, "talentCloudId", _descriptor10, this);
      _initializerDefineProperty(this, "firmSizes", _descriptor11, this);
      _initializerDefineProperty(this, "inputTypes", _descriptor12, this);
      _initializerDefineProperty(this, "expert", _descriptor13, this);
    }
    get userEmail() {
      if (this.email) {
        return this.email;
      } else if (this.expert.users.email) {
        return this.expert.users.email;
      }
      return '';
    }
    updateUser(property, event) {
      if (property === 'accept_terms') {
        this.expert.users[property] = !this.expert.users[property];
      } else {
        this.expert.users[property] = event.target.value;
      }
    }
    updateIdentity(property, event) {
      this.expert.identity[property] = event.target.value;
    }
    validateFields() {
      const {
        email,
        first_name,
        last_name,
        firm_name,
        firm_size,
        firm_website,
        accept_terms
      } = this.expert.users;
      const {
        key,
        confirm_password
      } = this.expert.identity;
      const user = {
        email,
        first_name,
        last_name,
        key,
        confirm_password,
        firm_name,
        firm_size,
        firm_website,
        accept_terms
      };
      const {
        validationResult,
        fieldError
      } = (0, _validation.validateRequiredFields)(user, {
        isSignIn: false,
        isValidateAdditional: !this.isFirstPage
      });
      if (user.email && !(0, _validation.validateEmail)(user.email)) {
        fieldError['email'] = true;
        validationResult['email'] = 'Email is not valid';
      }
      if (user.first_name && !(0, _validation.validateName)(user.first_name)) {
        fieldError['first_name'] = true;
        validationResult['first_name'] = 'First Name cannot contain special characters';
      }
      if (user.last_name && !(0, _validation.validateName)(user.last_name)) {
        fieldError['last_name'] = true;
        validationResult['last_name'] = 'Last Name cannot contain special characters';
      }
      if (user.confirm_password && !(0, _validation.passwordsMatch)(user.key, user.confirm_password)) {
        fieldError['key'] = true;
        fieldError['confirm_password'] = true;
        validationResult['confirm_password'] = 'New Password and Confirm Password do not match';
      }
      this.fieldError = fieldError;
      Object.keys(user).forEach(key => {
        if (validationResult[`${key}`]) {
          this.errors.push(validationResult[`${key}`]);
        }
      });
      return this.errors.length === 0;
    }
    createUser() {
      this.errors = [];
      if (!this.expert.users.email && this.userEmail) this.expert.users.email = this.userEmail;
      if (this.validateFields()) {
        this.isSignUpInProgress = true;
        this.expert.users.user_type = 'expert';
        const data = JSON.stringify({
          users: this.expert.users,
          identity: this.expert.identity
        });
        try {
          fetch(`/api/users`, {
            method: 'POST',
            body: data,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => {
            return response.json();
          }).then(json => {
            if (json.access_token) {
              this.auth.token = json.access_token;
              this.isFirstPage = !this.isFirstPage;
            } else {
              const error = json.error || '';
              if (error) {
                const errorMessage = error.split(',')[1];
                if (errorMessage) {
                  this.errors = [...this.errors, errorMessage.split('=>')[1].replace('"', '')];
                }
              }
              const errors = json.errors || [];
              if (errors.length) {
                const errorMessage = errors[0];
                this.errors = [...this.errors, errorMessage];
              }
            }
          }).catch(error => {
            this.errors = [...this.errors, error.toString().split(':')[1]];
            this.isSignUpInProgress = false;
          }).finally(() => {
            this.isSignUpInProgress = false;
            this.id_token = this.auth.token;
          });
        } catch (error) {
          this.errors = [...this.errors, error.toString().split(':')[1]];
          this.isSignUpInProgress = false;
        }
      }
    }
    get expertUrl() {
      return this.links.withToken('/expert/autofill');
    }
    get expertApplyOpportunityUrl() {
      return this.links.withToken(`/expert/opportunities/${this.opportunityId}/details?expertApplies=true`);
    }
    get expertTalentCloudUrl() {
      return this.links.withToken(`/expert/talentclouds/${this.talentCloudId}/details?expertApplies=true`);
    }
    updateSignupUser() {
      this.errors = [];
      const validFields = this.isIndividual || !this.isIndividual && this.validateFields();
      if (validFields) {
        const data = JSON.stringify({
          users: {
            profiles: {
              firm_name: this.expert.users.firm_name,
              firm_website: this.expert.users.firm_website,
              firm_size: this.expert.users.firm_size?.value
            }
          }
        });
        try {
          fetch(`/api/users`, {
            method: 'PATCH',
            body: data,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.auth.token
            }
          }).then(res => res.json()).then(json => {
            localStorage.setItem('email', this.expert.users.email);
            localStorage.setItem('userType', 'expert');
            if (this.opportunityId) {
              localStorage.setItem('opportunityId', this.opportunityId);
            }
            if (this.talentCloudId) {
              localStorage.setItem('talentCloudId', this.talentCloudId);
            }
            if (json.is_email_verified) {
              if (this.talentCloudId) {
                return window.location.assign(this.expertTalentCloudUrl);
              } else if (this.opportunityId) {
                return window.location.assign(this.expertApplyOpportunityUrl);
              } else {
                return window.location.assign(this.expertUrl);
              }
            } else {
              this.transitionToRoute('/signup/verify');
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    toggleInputType(property) {
      this.inputTypes = {
        ...this.inputTypes,
        [property]: this.inputTypes[property] === 'password' ? 'text' : 'password'
      };
    }
    socialSignUp(ssoType) {
      const uri = (0, _sso.generateUri)({
        isSignUp: true,
        ssoType,
        userType: 'expert'
      });
      window.location.assign(uri);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "links", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFirstPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isIndividual", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fieldError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isSignUpInProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "id_token", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "opportunityId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "talentCloudId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "firmSizes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        key: 1,
        value: 'Less than 100'
      }, {
        key: 2,
        value: '100 - 1000'
      }, {
        key: 3,
        value: 'More than 1000'
      }];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "inputTypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        key: 'password',
        confirm_password: 'password'
      };
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "expert", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        users: {
          email: '',
          first_name: '',
          last_name: '',
          user_type: '',
          firm_name: '',
          firm_website: '',
          firm_size: null,
          accept_terms: false
        },
        identity: {
          type: 'password',
          key: '',
          confirm_password: ''
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIdentity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateIdentity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSignupUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSignupUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleInputType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleInputType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "socialSignUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "socialSignUp"), _class.prototype)), _class);
  _exports.default = SignupExpertController;
});