define("itarp-landing-frontend/templates/signup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rjfbIg6L",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"component component-panel pad-t--3\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"panel panel-auth\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"panel-auth__header\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"panel-auth__header__subscript\"],[12],[1,\"\\n                Welcome to\\n              \"],[13],[1,\"\\n              \"],[10,\"h3\"],[14,0,\"panel-auth__header__title\"],[12],[1,\"\\n                Experfy TalentClouds\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"panel-auth__body\"],[12],[1,\"\\n              \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "itarp-landing-frontend/templates/signup.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});