define("itarp-landing-frontend/components/cards/training", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="component-wrapper">
    <div class="card-holder">
      <div class="lp-card lp-card--magenta lp-card--training">
        <div class="lp-card__body">
          <h3 class="lp-card__title for-training">
            Training & Upskilling Platform
          </h3>
          <p class="lp-card__text">
            Developed by industry thought leaders and Experfy in Harvard
            Innovation Lab. Sharp focus on domain-specific use-cases making you
            productive as soon as you leave the classroom.
          </p>
        </div>
        <div class="lp-card__footer">
          <a
            class="btn btn--primary"
            href="https://training.experfy.com/courses"
            target="_blank"
          >Browse Courses</a>
          <a
            class="btn btn-outline"
            href="https://training.experfy.com/instructors"
            target="_blank"
          >I want become an Instructor</a>
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "+g0q5aYN",
    "block": "[[[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-holder\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"lp-card lp-card--magenta lp-card--training\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"lp-card__body\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"lp-card__title for-training\"],[12],[1,\"\\n          Training & Upskilling Platform\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"lp-card__text\"],[12],[1,\"\\n          Developed by industry thought leaders and Experfy in Harvard\\n          Innovation Lab. Sharp focus on domain-specific use-cases making you\\n          productive as soon as you leave the classroom.\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"lp-card__footer\"],[12],[1,\"\\n        \"],[10,3],[14,0,\"btn btn--primary\"],[14,6,\"https://training.experfy.com/courses\"],[14,\"target\",\"_blank\"],[12],[1,\"Browse Courses\"],[13],[1,\"\\n        \"],[10,3],[14,0,\"btn btn-outline\"],[14,6,\"https://training.experfy.com/instructors\"],[14,\"target\",\"_blank\"],[12],[1,\"I want become an Instructor\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-landing-frontend/components/cards/training.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});