define("itarp-landing-frontend/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dFBgkqEo",
    "block": "[[[10,0],[15,0,[29,[\"page \",[52,[30,0,[\"page\",\"isAuthPage\"]],\"page-auth\"]]]],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"navbar\",\"component\",\"-outlet\"]]",
    "moduleName": "itarp-landing-frontend/templates/application.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});