define("itarp-landing-frontend/components/company-chip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="chip" ...attributes>
    {{@select.selected.name}}
    <button type="button" {{on "click" @clear}}>x</button>
  </span>
  */
  {
    "id": "5W2GAfrp",
    "block": "[[[11,1],[24,0,\"chip\"],[17,1],[12],[1,\"\\n  \"],[1,[30,2,[\"selected\",\"name\"]]],[1,\"\\n  \"],[11,\"button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,3]],null],[12],[1,\"x\"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@select\",\"@clear\"],false,[\"on\"]]",
    "moduleName": "itarp-landing-frontend/components/company-chip.hbs",
    "isStrictMode": false
  });
  class CompanyChipComponent extends _component2.default {}
  _exports.default = CompanyChipComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CompanyChipComponent);
});