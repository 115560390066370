define("itarp-landing-frontend/utils/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateRequiredFields = _exports.validateName = _exports.validateEmail = _exports.passwordsMatch = void 0;
  const validateRequiredFields = (model, _ref) => {
    let {
      isSignIn,
      isValidateAdditional,
      isClient = false
    } = _ref;
    const signUpValidations = {
      commonFields: [{
        key: 'email',
        value: 'Email'
      }, {
        key: 'first_name',
        value: 'First Name'
      }, {
        key: 'last_name',
        value: 'Last Name'
      }, {
        key: 'companyName',
        value: 'Company Name'
      }, {
        key: 'key',
        value: 'Password'
      }, {
        key: 'confirm_password',
        value: 'Confirm Password'
      }, {
        key: 'accept_terms',
        value: 'Accept terms and connditions'
      }],
      additionalFields: [{
        key: 'firm_name',
        value: 'Firm Name'
      }, {
        key: 'firm_website',
        value: 'Firm Website'
      }, {
        key: 'firm_size',
        value: 'Firm Size'
      }]
    };
    const signInValidations = [{
      key: 'email',
      value: 'Email'
    }, {
      key: 'password',
      value: 'Password'
    }];
    if (!model) return [];
    if (!Object.keys(model).length) return [];
    const fieldsToValidate = isSignIn ? signInValidations : !isValidateAdditional ? signUpValidations.commonFields : signUpValidations.additionalFields;
    const fieldError = {};
    const validationResult = {};
    fieldsToValidate.forEach(field => {
      if (!model[field.key]) {
        if (field.key === 'companyName') {
          if (!isSignIn && isClient) {
            fieldError[field.key] = true;
            validationResult[field.key] = `${field.value} is required`;
          }
        } else if (field.key === 'accept_terms') {
          fieldError[field.key] = true;
          validationResult[field.key] = `Please accept the terms and conditions to proceed further`;
        } else {
          fieldError[field.key] = true;
          validationResult[field.key] = `${field.value} is required`;
        }
      }
    });
    return {
      validationResult: validationResult,
      fieldError: fieldError
    };
  };
  _exports.validateRequiredFields = validateRequiredFields;
  const passwordsMatch = (password, confirmPassword) => {
    if (password && confirmPassword) {
      return password === confirmPassword;
    }
    return false;
  };
  _exports.passwordsMatch = passwordsMatch;
  const validateEmail = email => {
    return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  // firstname and lastname cannot have @ character
  _exports.validateEmail = validateEmail;
  const validateName = name => {
    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    return name && !format.test(name);
  };
  _exports.validateName = validateName;
});