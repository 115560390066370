define("itarp-landing-frontend/components/banners/welcome", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="lp-banner">
    <div class="lp-banner__inner">
      <div class="lp-banner__content">
        <h1 class="lp-banner__title">
          {{this.welcomeMessage}}
        </h1>
        <p class="lp-banner__text">
          Your platform for proactive recruiting, pipelining and deploying talent
          at speed
        </p>
      </div>
    </div>
  </div>
  */
  {
    "id": "N6G6pgib",
    "block": "[[[10,0],[14,0,\"lp-banner\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"lp-banner__inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"lp-banner__content\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"lp-banner__title\"],[12],[1,\"\\n        \"],[1,[30,0,[\"welcomeMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"lp-banner__text\"],[12],[1,\"\\n        Your platform for proactive recruiting, pipelining and deploying talent\\n        at speed\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-landing-frontend/components/banners/welcome.hbs",
    "isStrictMode": false
  });
  let BannersWelcomeComponent = (_class = class BannersWelcomeComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "auth", _descriptor, this);
    }
    get name() {
      return this.auth.sessionData?.name;
    }
    get welcomeMessage() {
      return this.name ? `Welcome, ${this.name}!` : 'Welcome!';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = BannersWelcomeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BannersWelcomeComponent);
});