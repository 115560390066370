define("itarp-landing-frontend/components/cards/talent", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="component-wrapper">
    <div class="card-holder">
      <div class="lp-card lp-card--frost lp-card--talent">
        <div class="lp-card__body">
          <h3 class="lp-card__title">
            Experfy Talent On-Demand
          </h3>
          <p class="lp-card__text lp-card__text--lg">
            Experfy TalentCloud platform gives you access to highly curated
            on-demand talent vetted by Experfy SMEs and categorized into skill-
            and role-specific TalentClouds. Get started by posting a job
            opportunity and watch the applications flow in no time.
          </p>
        </div>
        <div class="lp-card__footer">
          {{yield}}
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "9oPwxaCU",
    "block": "[[[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-holder\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"lp-card lp-card--frost lp-card--talent\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"lp-card__body\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"lp-card__title\"],[12],[1,\"\\n          Experfy Talent On-Demand\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"lp-card__text lp-card__text--lg\"],[12],[1,\"\\n          Experfy TalentCloud platform gives you access to highly curated\\n          on-demand talent vetted by Experfy SMEs and categorized into skill-\\n          and role-specific TalentClouds. Get started by posting a job\\n          opportunity and watch the applications flow in no time.\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"lp-card__footer\"],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-landing-frontend/components/cards/talent.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});